import { useState, useEffect, useRef } from "react";

const DeliverySec = () => {
    const profitCard = useRef(null);
    const lossCard = useRef(null);
    const [clientProfit, setClientProfit] = useState(true);

    useEffect(() => {
        const handleProfitCardClick = () => {
            setClientProfit(true);
        };
        const handleLossCardClick = () => {
            setClientProfit(false);
        };
        profitCard.current.addEventListener("mouseover", handleProfitCardClick);
        lossCard.current.addEventListener("mouseover", handleLossCardClick);
    },[]);

    useEffect(() => {
        if(clientProfit) {
            profitCard.current.classList.add("delivery-card-focus");
            lossCard.current.classList.remove("delivery-card-focus");
        } else {
            lossCard.current.classList.add("delivery-card-focus");
            profitCard.current.classList.remove("delivery-card-focus");
        }
    }, [clientProfit]);

    return(
        <section id="delivery-sec" className="reading-sec">
                <div id="delivery-dis">
                    <h2 className="text-glow text-center">交割方式</h2>
                    <p className="text-center">所有商品統一於每日13:47過帳總結金額</p>
                </div>
                <div id="delivery-cards">
                    <div id="profit-card" className="card delivery-card" ref={profitCard}>
                        <h3 className="text-center">客戶獲利</h3>
                        <p className="text-center">當日15點30分前，會將款項匯至您指定的銀行帳戶</p>
                    </div>
                    <div id="loss-card" className="card delivery-card" ref={lossCard}>
                        <h3 className="text-center">客戶虧損</h3>
                        <p className="text-center">請於當日15點30分前匯款完成</p>
                    </div>
                </div>
                <fieldset id="delivery-amount">
                    <legend className="text-glow text-center">交割金額</legend>
                    <p id="delivery-amount-text" className="text-center">月底最後一天結算日，帳上餘額未達1000元則掛帳至次月</p>
                </fieldset>
        </section>
    )
}

export default DeliverySec;
import Header from "./components/Header"
import Footer from "./components/Footer"
import IconBTN from "./components/IconBTN"
import FeatCard from "./components/FeatCard"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import "./Platform.css"

const Futures = () => {
    const navigate = useNavigate();

    const toVideo = () => {
        const targetVideo = document.querySelector("#futures-video");
        navigate("/futures-teaching/#futures-video");

        if(targetVideo){
            targetVideo.play();
        }
    }

    return(
        <>
            <Helmet>
                <title>聯合資訊 | 期貨平台</title>
                <meta name="description" content="聯合資訊的期貨平台簡單易用，具備放大鏡、十字線鎖定、左右移動週期的特色功能，以專業技術讓您輕鬆下單。" />
                <meta name="keywords" content="聯合資訊, 期貨平台, 模擬交易, 介面簡單"></meta>
            </Helmet>
            <Header />
            <main>
                <section id="btns-sec" className="reading-sec">
                    <div id="platform-name">
                        <img src="/pictures/futures-logo.png" alt="futures-logo" />
                        <h1>期貨平台</h1>
                    </div>
                    <div className="btns-box">
                    <IconBTN imgSrc={"/pictures/smartphone_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"mobile"} btnText={"手機版登入"} link={"https://www.ft666.net/mm/"} isNewPage={true} />
                        <IconBTN imgSrc={"/pictures/desktop_windows_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"desktop"} btnText={"網頁版登入"} link={"https://www.ft666.net/web/"} isNewPage={true} />
                        <IconBTN imgSrc={"/pictures/terminal_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"software"} btnText={"程式版下載"} link={"/sw/聯合.msi"} isNewPage={false} />
                    </div>
                </section>
                <section id="dis-sec" className="reading-sec">
                    <h2 className="text-center desktop-text">輕鬆，而且專業可靠!</h2>
                    <button id="go-btn" className="go-btn" onClick={toVideo}>觀看操作影片</button>
                    <p>
                        透過公司代墊期貨客戶保證金，海外期貨免換匯手續，一律以TWD新台幣計價，幫助客戶轉單，公司賺取手續費價差，設定每日最高上限盈虧控制客戶風險。
                    </p>
                    <div id="features">
                        <div className="card">
                            <img src="/pictures/stock-1.png" alt="stock-market-1" />
                            <h3>輕鬆管理您的投資</h3>
                            <p>提供先進的技術圖表，即時的報價，支援軟體下載、網頁瀏覽器、ipad、iphone、安卓等，交易者可以隨時隨地透過簡單易明的介面建立及管理交易。</p>
                        </div>
                        <div className="card">
                            <img src="/pictures/stock-market-1.png" alt="stock-1" />
                            <h3>專業技術讓您輕鬆下單</h3>
                            <p>聯合資訊專為個人體驗金融市場脈動的最佳通道。 在平台系統技術方面，公司的技術團隊秉承多年來的專業，不斷地為使用者創造了更加安全以及可靠的網路環境與指標性的金融商品。</p>
                        </div>
                        {/* <p>
                            透過公司代墊期貨客戶保證金，海外期貨免換匯手續，一律以TWD新台幣計價，幫助客戶轉單，公司賺取手續費價差，設定每日最高上限盈虧控制客戶風險。
                        </p> */}
                        <video id="futures-video" className="show-video" poster="/pictures/futures-video-poster.png" controls loop muted>
                            <source src="https://storage.googleapis.com/un077-videos/un077-futures-video-rework_20240722.mp4" type="video/mp4" />
                            您的瀏覽器不支援 HTML5 視訊。
                        </video>
                    </div>
                </section>
                <section id="key-sec" className="reading-sec">
                    <h2>平台特色與優勢</h2>
                    <p>
                        公司採用亞洲知名平台開發團隊所提供之交易平台、高度人性化的設計，此模擬交易平台，提供模擬的仿真交易，在您進入高風險市場前，可透過本系統，體驗、教學、練習交易
                    </p>
                    <div id="video-wrapper">
                        {/* <div id="sticky-video-container">
                            <video controls loop muted>
                                <source src="https://storage.googleapis.com/un077-videos/un077-futures-video-rework_20240722.mp4" type="video/mp4" />
                            </video>
                            
                        </div> */}
                        <div id="key-feat-grid">
                            <FeatCard title={"操作介面簡單"} text={"簡單快速 交易獲利虧損 簡單明瞭"} imgSrc={"/pictures/smartphone_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"放大鏡"} text={"可以放大及縮小K線週期"} imgSrc={"/pictures/search_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"左右移動周期與時間"} text={"清楚看到市場變化"} imgSrc={"/pictures/arrows_outward_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"設停損停利點簡單快速"} text={"依照個人習慣設定損利方式"} imgSrc={"/pictures/motion_photos_paused_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"十字線鎖定"} text={"指標數據 指標選擇多樣化"} imgSrc={"/pictures/point_scan_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                        </div>
                    </div>                    
                </section>
            </main>
            <Footer />
        </>
    )
}

export default Futures;
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { useRef, useEffect, useContext } from 'react';
import { LocationContext } from '../../App';

const Earth3D = () => {
    // const [width, setWidth] = useState(600);
    // const [height, setHeight] = useState(600);

    const width = 600;
    const height = 600;

    const {currentLocation} = useContext(LocationContext);

    const earthWrapper = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const earthRef = useRef(null);
    

    useEffect(() => {
        // scene & camera
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(39.6, width/height, 0.1, 1000);
        cameraRef.current = camera;
        camera.position.z = 400;

        // renderer
        const renderer = new THREE.WebGLRenderer({alpha: true});
        rendererRef.current = renderer;
        renderer.setSize(width, height);

        earthWrapper.current.appendChild( renderer.domElement );



        // gltf model
        const loader = new GLTFLoader();
        let earth;

        const earthPivot = new THREE.Object3D();
        earthPivot.rotation.z = THREE.MathUtils.degToRad(-23.5);
        earthPivot.rotation.x = THREE.MathUtils.degToRad(20);
        scene.add(earthPivot);
        
        // loader.load(
        //     "/3d/earth-animation-forWeb-local.gltf",
        //     (gltf) => {
        //         earth = gltf.scene;
        //         earthPivot.add( earth );
        //         earthRef.current = earth; 

        //         earth.traverse((mesh) => {
        //             console.log(mesh.name);
        //             if(mesh.name === "taiwan" || mesh.name === "japan" || mesh.name === "earth1" || mesh.name === "earth" ){
        //                 // console.log(mesh);
        //                 mesh.visible = false;
        //             }
                    
        //         })
        //     },
        //     undefined,
        //     (error) => {
        //         console.log(error);
        //     }
        // )
        loader.load(
            "/3d/earth-animation-forWeb.gltf",
            (gltf) => {
                earth = gltf.scene;
                earthPivot.add( earth );
                earthRef.current = earth; 
            },
            undefined,
            (error) => {
                console.log(error);
            }
        )


        const animate = () => {
            if(earth){
                earth.rotation.y += 0.002; 
            }
            renderer.render( scene, camera );
        }
        renderer.setAnimationLoop( animate );

        const earthWrapperCurrent = earthWrapper.current;
        return () => {            
            renderer.dispose();
            if (earthWrapperCurrent) {
                earthWrapperCurrent.removeChild(renderer.domElement);
            }
        };
    }, [])

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        const rotateToLocation = async (angle) => {
            if(earthRef.current){
                console.log(angle);
                console.log(earthRef.current.rotation.y);
                const loop = THREE.MathUtils.degToRad(360);
    
                let targetAngle = THREE.MathUtils.degToRad(angle);
                targetAngle -= loop;
                let currentAngle = earthRef.current.rotation.y;
    
    
                while(targetAngle < currentAngle){
                    targetAngle += loop;
                }
                while((targetAngle - currentAngle) > 0.001){
                    await delay(15); // Delay in milliseconds
                    earthRef.current.rotation.y += 0.05;
                    currentAngle = earthRef.current.rotation.y;    
                }
    
            }
    
        }

        rotateToLocation(currentLocation.aniTimestamp);
    }, [currentLocation]);

    
    return(
        <div ref={earthWrapper} id="earth-3d-wrapper" style={{width: "100%", height: "100%"}}>

        </div>
    )
}

export default Earth3D;
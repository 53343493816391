import { useRef, useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
    const footerSpacer = useRef(null);
    const standardFooter = useRef(null);

    const [footerSpace, setFooterSpace] = useState(0);

    useEffect( () => {
        const setFooterSpacerHeight = () => {
            let footerHeight = standardFooter.current.clientHeight;
            setFooterSpace(footerHeight);
        }
        setFooterSpacerHeight();
        window.addEventListener("resize", setFooterSpacerHeight());
    }, []);

    return(
        <>
            <div id="footer-spacer" ref={footerSpacer} style={{height: footerSpace}}></div>
            <footer id="standard-footer" ref={standardFooter}>
                <div id="footer-div" className="reading-sec">
                    <h2>注意事項</h2>
                    <p>為確保雙方權益，交易規則請詳細閱讀。<br />下單仍須注意自身資金風險，若未依規定時間交割，公司將列為禁止往來戶。</p>
                </div>
            </footer>
        </>
        
    );
}

export default Footer;
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import LocationSelector from "./LocationSelector";
import "./Header.css";

const Header = () => {
    const [headerSpace, setHeaderSpace] = useState(0);
    const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
    const headerSpacerRef = useRef(null);
    const navigate = useNavigate();

    useEffect( () => {
        const setHeaderSpacerHeight = () => {
            if (headerSpacerRef.current) {
                const header = document.querySelector("#header");
                const height = header.clientHeight;
                const style = window.getComputedStyle(header);
                const marginTop = parseFloat(style.marginTop);
                const marginBottom = parseFloat(style.marginBottom);
                setHeaderSpace(height + marginTop + marginBottom);
            }
        }

        setHeaderSpacerHeight();
        window.addEventListener("resize", setHeaderSpacerHeight);
    },[])

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }else{
            window.scrollTo(0, 0);
        }
      }, [location]);
    

    return(
        <>
            <header className="stander-header" id="header">
                <div onClick={() => {
                    navigate("/");
                    setHeaderMenuOpen(false);
                }} id="brand-wrapper" className="link-wrapper pointer">
                    <img className="header-logo" src="/logo/un077-logo-white.svg" alt="un077-logo" />
                    <img className="header-logo logo-glow" src="/logo/un077-logo-white.svg" alt="un077-logo" />
                    <p id="brand-name">聯合資訊</p>
                </div>
                <HeaderNav headerMenuOpen={headerMenuOpen} setHeaderMenuOpen={setHeaderMenuOpen} />
                <div id="home-link" onClick={() => {
                    navigate("/");
                    setHeaderMenuOpen(false);
                }}>
                    <p>關於我們</p>    
                </div>  
                <div id="scta" onClick={() => {
                    navigate("/#line-sec");
                    setHeaderMenuOpen(false);
                }}>
                    <p>聯絡我們</p>    
                </div>            
                <button id="apply-now" onClick={() => {
                    navigate("/apply-now");
                    setHeaderMenuOpen(false);
                }}>立即申請</button>
                <LocationSelector setHeaderMenuOpen={setHeaderMenuOpen} />
                <div id="backdrop">
                    
                </div>
            </header>
            <div id="header-spacer"
                 ref={headerSpacerRef}
                 style={{height: headerSpace}}
            ></div>
        </>
        
        
    );
}
export default Header;
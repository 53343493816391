import Header from "./components/Header"
import Footer from "./components/Footer"
import FeatCard from "./components/FeatCard"
import StockLogin from "./components/StockLogin"
import { useRef } from "react"
import { Helmet } from "react-helmet"
import "./Platform.css"
import "./components/IconBTN.css"

const Stock = () => {
    const stockLogin = useRef(null);
    const toggleDialog = (e) => {
        if(!stockLogin.current){
            return;
        }
        const target = stockLogin.current;
        target.hasAttribute("open")
            ? target.close()
            : target.showModal()
    }
    return(
        <>
            <Helmet>
                <title>聯合資訊 | 股票平台</title>
                <meta name="description" content="聯合資訊的股票平台提供AI智慧化選股功能，能夠清楚看到市場變化，並輕鬆操作" />
                <meta name="keywords" content="聯合資訊, 股票平台, AI選股, 介面簡單"></meta>
            </Helmet>
            <Header />
            <main>
                <section id="btns-sec" className="reading-sec">
                    <div id="platform-name">
                        <img src="/pictures/stock-logo.png" alt="futures-logo" />
                        <h1>股票平台</h1>
                    </div>
                    <div className="btns-box">
                        <button className="icon-btn" onClick={toggleDialog}>
                            <div>
                                <img src="/pictures/desktop_windows_24dp_FILL0_wght400_GRAD0_opsz24.svg" alt="快速登入" />
                                <span>快速登入</span>  
                            </div>                                     
                        </button>
                        {/* <IconBTN imgSrc={"/pictures/terminal_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"software"} btnText={"程式版下載"} link={"/sw/聯合.msi"} isNewPage={false} /> */}
                        {/* <IconBTN clickAction={toggleDialog} imgSrc={"/pictures/desktop_windows_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"desktop"} btnText={"快速登入"} isNewPage={false} /> */}
                        {/* <IconBTN imgSrc={"/pictures/smartphone_24dp_FILL0_wght400_GRAD0_opsz24.svg"} imgName={"mobile"} btnText={"手機版登入"} link={"https://www.ft666.net/mm/"} isNewPage={true} /> */}
                    </div>
                </section>
                {/* <section id="dis-sec" className="reading-sec">
                    <h2 className="text-center">輕鬆，而且專業可靠!</h2>
                    <div id="features">
                        <div className="card">
                            <img src="/pictures/stock-1.png" alt="stock-market-1" />
                            <h3>輕鬆管理您的投資</h3>
                            <p>提供先進的技術圖表，即時的報價，支援軟體下載、網頁瀏覽器、ipad、iphone、安卓等，交易者可以隨時隨地透過簡單易明的介面建立及管理交易。</p>
                        </div>
                        <div className="card">
                            <img src="/pictures/stock-market-1.png" alt="stock-1" />
                            <h3>專業技術讓您輕鬆下單</h3>
                            <p>聯合資訊專為個人體驗金融市場脈動的最佳通道。 在平台系統技術方面，公司的技術團隊秉承多年來的專業，不斷地為使用者創造了更加安全以及可靠的網路環境與指標性的金融商品。</p>
                        </div>
                        <p>
                            透過公司代墊期貨客戶保證金，海外期貨免換匯手續，一律以TWD新台幣計價，幫助客戶轉單，公司賺取手續費價差，設定每日最高上限盈虧控制客戶風險。
                        </p>
                    </div>
                </section> */}
                <section id="key-sec" className="reading-sec">
                    <h2 style={{textAlign: "center"}}>平台特色</h2>
                    {/* <p>
                        公司採用亞洲知名平台開發團隊所提供之交易平台、高度人性化的設計，此模擬交易平台，提供模擬的仿真交易，在您進入高風險市場前，可透過本系統，體驗、教學、練習交易
                    </p> */}
                    <div id="video-wrapper">
                        {/* <div id="sticky-video-container">
                            <iframe id="futures-iframe" title="futures-video" type="text/html" src="https://www.youtube.com/embed/rX3wxTkb6Uc?version=3&loop=1&playlist=rX3wxTkb6Uc&autoplay=1&rel=0&mute=1&origin=http://example.com"></iframe>
                        </div> */}
                        <div id="key-feat-grid">
                            <FeatCard title={"AI智慧化選股功能"} text={"提供當日個股行情、智慧選股以及多項報價指標"} imgSrc={"/pictures/learning-machine-ai-icon.svg"} />
                            <FeatCard title={"清楚看到市場變化"} text={"市場報價清晰易懂，逐筆揭示，讓您可以精準投資"} imgSrc={"/pictures/saved_search_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"操作介面簡單"} text={"簡單快速 交易獲利虧損 簡單明瞭"} imgSrc={"/pictures/desktop_windows_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            {/* <FeatCard title={"放大鏡"} text={"可以放大及縮小K線週期"} imgSrc={"/pictures/search_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"左右移動周期與時間"} text={"清楚看到股票市場"} imgSrc={"/pictures/arrows_outward_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"設停損停利點簡單快速"} text={"依照個人習慣設定損利方式"} imgSrc={"/pictures/motion_photos_paused_24dp_FILL0_wght400_GRAD0_opsz24.svg"} />
                            <FeatCard title={"十字線鎖定"} text={"指標數據 指標選擇多樣化"} imgSrc={"/pictures/point_scan_24dp_FILL0_wght400_GRAD0_opsz24.svg"} /> */}
                        </div>
                    </div>                    
                </section>
                <StockLogin ref={stockLogin} />
            </main>
            <Footer />
        </>
    )
}

export default Stock;
import Header from "./components/Header";
import DeliverySec from "./components/DeliverySec";
// import EarthAnim from "./components/EarthAnim";
import Earth3D from "./components/Earth3D";
import ArrowBTNs from "./components/ArrowBTNs";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Home.css";

const Home = () => {
    const navigate = useNavigate();
    return(
        <>
            <Helmet>
                <title>聯合資訊 | 各類金融商品服務</title>
                <meta name="description" content="聯合資訊自2018年從電話下單轉型為網路電子下單，提供保障客戶隱私及安全交易的下單平台，並增加更多元的金融商品，推動金融商品與國際接軌。" />
                <meta name="keywords" content="聯合資訊, 期貨平台, 股票平台, 網路下單, 數位化下單"></meta>
            </Helmet>
            <Header />
            <div id="earth-wrapper">
                {/* <EarthCanvas /> */}
                {/* <EarthAnim /> */}
                <Earth3D />
                {/* <video autoPlay loop muted playsInline>
                    <source src="/videos/un077-earth_20240531-GP_VP9-H265.mov" type="video/mov; codecs=hevc" />
                    <source src="/videos/un077-earth_20240531-GP_VP9.webm" type="video/webm" />
                </video> */}
            </div>
            <section id="landing-sec" className="reading-sec">
                <h1 id="main-title">聯合資訊</h1>
                <h2>各類金融商品服務</h2>
                <div id="btns-wrapper">
                    <button onClick={() => {
                        navigate("/");
                        navigate("/futures-teaching")
                    }} className="cta">期貨平台</button>
                    <button onClick={() => navigate("/stock-teaching")} className="cta">股票平台</button>
                </div>
            </section>
            <section id="description-sec" className="reading-sec">
                <div id="description" className="card">
                    {/* <h3 id="sub-title" className="text-glow">各類金融商品服務</h3> */}
                    <p>本公司自2018年起

                        為因應全台推動金融商品與國際接軌 <br />
                        全方位E化、數位化等政策考量<br />
                        全面轉型成為網路電子下單<br />
                        增加了更多元化的金融商品種類<br />
                        提供保障客戶隱私及安全交易的下單平台<br />
                        以誠為本，為本公司核心經營理念
                    </p>
                    {/* <button id="learn-more-btn">了解更多</button> */}
                </div>
            </section>
            <section id="line-sec" className="reading-sec">
                <h4 className="text-center">請聯繫您的接洽專員，或與我們聯繫</h4>
                <h2 id="line-title" className="text-center text-glow">歡迎使用Line聯繫我們</h2>
                <div id="line-wrapper" className="link-wrapper">
                    <p style={{textAlign: "center", paddingBottom: "1rem"}}>點擊下方圖示前往官方 Line</p>
                    <a href="https://line.me/R/ti/p/@030golnu?from=page&accountId=030golnu" target="_blank" rel="noopener noreferrer" className="link-sensor" id="line-link"> 
                    <img className="line-logo" src="/pictures/LINE_logo.svg" alt="LINE-logo" />
                    {/* <img className="line-logo" src="/pictures/LINE_logo_white.svg" alt="LINE-logo" />
                    <img className="line-logo line-glow" src="/pictures/LINE_logo_white.svg" alt="LINE-logo" /> */}
                    </a>
                    
                </div>
            </section>
            <section id="count-sec" className="reading-sec">
                <div id="settlement-card" className="card">
                    <h2 className="text-center text-glow">結算方式</h2>
                    <p id="settlement-dis" className="text-center">為保障雙方權益，期貨、股票合併結算，若加總金額未達兌匯額度，則掛帳至每日結算交收條件時，完成交割</p>
                </div>                
            </section>
            <section id="cards-sec" className="reading-sec">
                <div className="card qa-card">
                    <img className="qa-img" src="/pictures/company-55-2-story-1.png" alt="" />
                    <h3 className="text-glow qa-title">股票免交割金是什麼?</h3>
                    <p>客戶於股票市場交易股票在確定下單完成後，就會面臨股票交割扣款的問題了，為了使客戶資金可以靈活運用，公司先行提供股票額度讓客戶下單，以您操作的股票獲利與虧損在收盤後進行結算，若股票為當日未平倉單，公司則賺取留倉費用，不須股票交割金。</p>
                </div>
                <div className="card qa-card">
                    <img className="qa-img" src="/pictures/company-55-2-story-2.png" alt="" />
                    <h3 className="text-glow qa-title">期貨為什麼不需要繳交保證金?</h3>
                    <p>透過公司代墊期貨客戶保證金，海外期貨免換匯手續，一律以TWD新台幣計價，幫助客戶轉單，公司賺取手續費價差，設定每日最高上限盈虧控制客戶風險。</p>
                </div>
            </section>
            <DeliverySec />
            <section className="reading-sec">
                <h2 id="cta-bottom-title" className="text-glow text-center">立即體驗</h2>
                <ArrowBTNs />
            </section>
            <Footer />
        </>
    );
}

export default Home;
const url = "https://tt9898.net:1443/api/validation";

export const Login = (e, id, password, cleanFunction) => {
    e.preventDefault();
    console.log("submit");
    const loginInfo = getLoginForm(id, password);
    fetchStockAPI(url, loginInfo.id, loginInfo.password).then(isFetchOK => {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("token");
        if(isFetchOK){
            goToStockPage(id, token);
            cleanFunction();
        }
        
    }).catch(error => {
        console.error('Error during login:', error);
    });
}

const getLoginForm = (id, password) => {
    if(!id){id = ""};
    if(!password){password = ""};

    const loginInfo = {
        id: id,
        password: password,
    }

    return loginInfo;
}

//zwtest601
const fetchStockAPI = (url, id, password) => {
    const formData = new FormData();
    formData.append("LoginAccount", id);
    formData.append("LoginPassword", password);
    return fetch(url, {
        method: "POST",
        body: formData,
    }).then(response => {
        const apiResponse = response.json();
        return apiResponse;
    }).then(response => {
        if(response.ErrorMsg !== ""){
            alert(response.ErrorMsg);
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            return false;
        }else{
            alert("登入成功");
            localStorage.setItem("userId", response.UserId);
            localStorage.setItem("token", response.Token);
            return true;
        }        
    }).catch(error => {
        console.log(error);
        return false;
    })
}

const goToStockPage = (id, token) => {
    alert("開啟新頁面");
    const confirmedURL = `https://tt9898.net:1443/market.php?UserID=${id}&UserToken=${token}&ReturnURL=https://tt9898.net&lang=zh_TW`;
    window.open(confirmedURL, "_blank", "noopener,noreferrer");

}

// const cleanInput = () => {

// }
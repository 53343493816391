import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dialog from "./components/Dialog";
import { Helmet } from "react-helmet";
import "./ApplyNow.css";


// send mail through EmailJS
const ApplyNow = () => {
    const contactForm = useRef(null);
    const gRecaptcha = useRef(null);
    const ruleReadInput = useRef(null);
    const ruleReadText = useRef(null);
    const [ruleReadMSG, setRuleReadMSG] = useState("未同意遵守相關交易規範");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // const [ruleOpenCount, setRuleOpenCount] = useState(0);
    const [ruleChecked, setRuleChecked] = useState(false);

    const navigate = useNavigate();

    // const handleDialogOpen = (event) => {
    //     event.stopPropagation();
    //     setIsDialogOpen(true);
    //     setRuleOpenCount(ruleOpenCount + 1);
    //     console.log("ruleOpenCount: ", ruleOpenCount);
    // }

    const changeRuleReadText = () => {
        let msg = ""
        if(ruleReadInput.current.checked){
            msg = "已同意";
        } else{
            msg = "未同意";
        }
        setRuleReadMSG(msg + "遵守相關交易規範");
    }

    const [selectedOption, setSelectedOption] = useState('自宅'); // 设置默认选项
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // 更新选中的状态
    };

    const [idSelectedOption, setIdSelectedOption] = useState('自宅'); // 设置默认选项
    const handleIdOptionChange = (event) => {
        setIdSelectedOption(event.target.value); // 更新选中的状态
    };

    const [incomeSelectedOption, setIncomeSelectedOption] = useState('薪資或營業所得'); // 设置默认选项
    const handleIncomeOptionChange = (event) => {
        setIncomeSelectedOption(event.target.value); // 更新选中的状态
    };

    const [enmergencySelectedOption, setEnmergencySelectedOption] = useState('朋友'); // 设置默认选项
    const handleEnmergencyOptionChange = (event) => {
        setEnmergencySelectedOption(event.target.value); // 更新选中的状态
    };

    const [defaultSelectedOption, setDefaultSelectedOption] = useState('無'); // 设置默认选项
    const handleDefaultOptionChange = (event) => {
        setDefaultSelectedOption(event.target.value); // 更新选中的状态
    };
    
    const sendEmail = (event) => {
        event.preventDefault();
        console.log("submit");
        
        let formData = new FormData(contactForm.current); // information that POST to EmailJS
        if(formData.get("stock") !== "股票平台" && formData.get("futures" ) !== "期貨平台"){
            alert("請選擇至少一個平台");
            return;
        } else if(formData.get("rule-read") !== "on"){
            alert("您必須詳讀並勾選\"交易規則\" ");
            return;
        } else if(document.getElementById("g-recaptcha-response").value === ""){
            alert("您必須進行\"我不是機器人驗證\" ");
            return;
        }
        const platformMessage = () => {
            const isStock = formData.get("stock") === "on"? " 股票平台 " : " ";
            const isFutures = formData.get("futures") === "on"? " 期貨平台 " : " ";
            return (isStock + isFutures);
        }
        const readMessage = () => {
            return ((formData.get("rule-read") === "on"? "已同意" : "未同意" ) + "遵守相關交易規範");
        }

        formData.append("platform", platformMessage());
        formData.append("read-rule-message", readMessage());
        formData.append("g-recaptcha-response", document.getElementById("g-recaptcha-response").value);
    



        emailjs.init("VEPF6esogt8XY8woB"); // POST request handle
        emailjs.sendForm('service_xpl32bf', 'template_un077', contactForm.current).then(
            (response) => {
              console.log('SUCCESS!', response.status, response.text);
              alert("郵件成功發送");
              contactForm.current.reset();
            },
            (error) => {
              console.log('FAILED...', error);
              alert("郵件發送失敗，請確認欄位填寫格式正確及機器人驗證以勾選，或稍後再嘗試...");
            },
        );
    }

    const reCAPTCHA = () => { // add reCAPTCHA script from Google
        const reCAPTCHAscript = document.createElement("script");
        reCAPTCHAscript.src = "https://www.google.com/recaptcha/api.js";
        reCAPTCHAscript.async = true;
        return reCAPTCHAscript;
    }


    useEffect(() => { // add functions and remove the added ones when rerender
        const reCAP = reCAPTCHA();
        const contactFormRef = contactForm.current;
        const ruleReadInputRef = ruleReadInput.current;
        
        ruleReadInputRef.addEventListener("change",changeRuleReadText);
        document.head.appendChild(reCAP);
        contactFormRef.addEventListener("submit", sendEmail);

        changeRuleReadText();

        return () => {
            if( contactFormRef ){
                contactFormRef.removeEventListener("submit", sendEmail);
            }
            ruleReadInputRef.removeEventListener("change",changeRuleReadText);            
            document.head.removeChild(reCAP);
        }
    });

    useEffect(() => {
        const uploadBTN = document.querySelectorAll("input[type='file']");
        // console.log(uploadBTN);
        uploadBTN[0].innerText = "515";
    })

    
    return(
        <>
            <Helmet>
                <title>聯合資訊 | 申請測試</title>
                <meta name="description" content="立即提交開戶資訊，聯合資訊將立即安排專員為您服務" />
                <meta name="keywords" content="聯合資訊, 期貨平台, 股票平台, 開戶申請"></meta>
            </Helmet>
            <Header />
            <main>
                <section className="reading-sec">
                    <form id="contact-form" ref={contactForm} action="">
                        <div id="title">
                            <h1>立即申請</h1>
                            {/* <p>我們將立即安排專員與您聯絡!</p> */}
                            <button className="go-btn" onClick={() => {navigate("/#line-sec")}}>有任何問題請與我們的專員聯絡→</button>
                        </div>
                        <label >申請平台</label>
                        <fieldset id="platform">
                            <div className="fieldset-item">
                                <input id="stock" className="checkbox" name="stock" type="checkbox" value="股票平台" /><label htmlFor="stock">股票平台</label>
                            </div>
                            <div className="fieldset-item">
                                <input id="futures" className="checkbox" name="futures" type="checkbox" value="期貨平台" /><label htmlFor="futures">期貨平台</label>
                            </div>                            
                        </fieldset>
                        <label htmlFor="customer-name">姓名</label><input id="customer-name" name="customer-name" type="text" placeholder="必填" required autoComplete="name"/>
                        <label htmlFor="customer-phone">手機號碼</label><input id="customer-phone" name="customer-phone" type="tel" placeholder="必填" required autoComplete="tel"/>
                        <label htmlFor="line-id">Line ID</label><input id="line-id" name="line-id" type="text" placeholder="選填" autoComplete="nickname"/>
                        <label htmlFor="customer-tel">住家電話</label><input id="customer-tel" name="customer-tel" type="tel" placeholder="選填" autoComplete="tel"/>
                        <label htmlFor="customer-address" >現居地址</label>                        
                        <div className="address" >
                            <input  id="customer-address" name="customer-address" type="text" placeholder="必填" required/>
                            <fieldset>
                                <input id="address-self" name="address-type" type="radio" value="自宅" checked={selectedOption === '自宅'} onChange={handleOptionChange} /> <label htmlFor="address-self">自宅</label>
                                <input id="address-rent" name="address-type" type="radio" value="租賃" checked={selectedOption === '租賃'} onChange={handleOptionChange} /> <label htmlFor="address-rent">租賃</label>
                                <input id="address-friend" name="address-type" type="radio" value="朋友" checked={selectedOption === '朋友'} onChange={handleOptionChange} /> <label htmlFor="address-friend">朋友</label>
                                <input id="address-family" name="address-type" type="radio" value="親屬" checked={selectedOption === '親屬'} onChange={handleOptionChange} /> <label htmlFor="address-family">親屬</label>
                            </fieldset>
                        </div>
                        <label htmlFor="customer-id-address" >戶籍地址</label>
                        <div className="address" >
                            <input id="customer-id-address" name="customer-id-address" type="text" placeholder="必填" required/>
                            <fieldset>
                                <input id="id-address-self" name="id-address-type" type="radio" value="自宅" checked={idSelectedOption === '自宅'} onChange={handleIdOptionChange} /> <label htmlFor="id-address-self">自宅</label>
                                <input id="id-address-rent" name="id-address-type" type="radio" value="租賃" checked={idSelectedOption === '租賃'} onChange={handleIdOptionChange} /> <label htmlFor="id-address-rent">租賃</label>
                                <input id="id-address-friend" name="id-address-type" type="radio" value="朋友" checked={idSelectedOption === '朋友'} onChange={handleIdOptionChange} /> <label htmlFor="id-address-friend">朋友</label>
                                <input id="id-address-family" name="id-address-type" type="radio" value="親屬" checked={idSelectedOption === '親屬'} onChange={handleIdOptionChange} /> <label htmlFor="id-address-family">親屬</label>
                            </fieldset>
                        </div>
                        
                        <label htmlFor="customer-email">Email</label><input id="customer-email" name="customer-email" type="email" placeholder="選填"  autoComplete="email"/>
                        <hr />
                        <label htmlFor="income-type">所得類型</label>
                        <fieldset>
                            <div className="fieldset-item">
                                <input id="income-salary" className="checkbox" name="income-type" type="radio" value="薪資或營業所得" checked={incomeSelectedOption === '薪資或營業所得'} onChange={handleIncomeOptionChange} /> <label htmlFor="income-salary">薪資或營業所得</label>
                            </div>
                            <div className="fieldset-item">
                                <input id="income-other" className="checkbox" name="income-type" type="radio" value="其他" checked={incomeSelectedOption === '其他'} onChange={handleIncomeOptionChange} /> <label htmlFor="income-other">其他</label>
                            </div>
                        </fieldset>
                        <label htmlFor="company-name" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }}>公司名稱</label><input id="company-name" name="company-name" type="text" autoComplete="organization" placeholder="選填" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }} />
                        <label htmlFor="company-tel" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }}>公司電話</label><input id="company-tel" name="company-tel" type="tel" autoComplete="tel" placeholder="選填" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }} />
                        <label htmlFor="company-address" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }}>公司地址</label><input id="company-address" name="company-address" type="text" placeholder="選填" style={{ display: incomeSelectedOption === '薪資或營業所得' ? 'initial' : 'none' }} />
                        <label htmlFor="other-icome" style={{ display: incomeSelectedOption === '其他' ? 'initial' : 'none' }}>詳述所得來源</label><input id="other-icome" name="other-icome" type="text" placeholder="選填" style={{ display: incomeSelectedOption === '其他' ? 'initial' : 'none' }} />
                        <hr />
                        <label htmlFor="emergency-name">緊急聯絡人</label>
                        <div className="address" >
                            <input id="emergency-name" name="emergency-name" type="text" placeholder="必填" required/>
                            <fieldset>
                                <input id="emergency-friend" name="emergency-type" type="radio" value="朋友" checked={enmergencySelectedOption === '朋友'} onChange={handleEnmergencyOptionChange} /> <label htmlFor="emergency-friend">朋友</label>
                                <input id="emergency-family" name="emergency-type" type="radio" value="親屬" checked={enmergencySelectedOption === '親屬'} onChange={handleEnmergencyOptionChange} /> <label htmlFor="emergency-family">親屬</label>
                                <input id="emergency-colleague" name="emergency-type" type="radio" value="同事" checked={enmergencySelectedOption === '同事'} onChange={handleEnmergencyOptionChange} /> <label htmlFor="emergency-colleague">同事</label>
                                <input id="emergency-other" name="emergency-type" type="radio" value="其他" checked={enmergencySelectedOption === '其他'} onChange={handleEnmergencyOptionChange} /> <label htmlFor="emergency-other">其他</label>
                            </fieldset>
                        </div>
                        <label htmlFor="emergency-tel">緊急聯絡人電話</label><input id="emergency-tel" name="emergency-tel" type="tel" placeholder="必填" required/>
                        <label htmlFor="emergency-address">緊急聯絡人地址</label><input id="emergency-address" name="emergency-address" type="text" placeholder="選填" />
                        <hr />
                        <label htmlFor="id-card-front">國民身分證 (正面)</label><input id="id-card-front" name="id-card-front" type="file" required/>
                        <label htmlFor="id-card-rear">國民身分證 (反面)</label><input id="id-card-rear" name="id-card-rear" type="file" required/>
                        <label htmlFor="bill">開戶本人任一帳單<br /><span>(內文需顯示收件地址)</span></label><input id="bill" name="bill" type="file" required/>
                        <label htmlFor="bank-account">開戶本人銀行帳號</label><input id="bank-account" name="bank-account" type="file" required/>
                        <hr />
                        <label htmlFor="contact-time">適合專員電訪的時間</label>
                        <fieldset id="contact-time">
                            <div className="fieldset-item">
                                <input id="morning" className="checkbox" name="morning" type="checkbox" value="早上" /><label htmlFor="morning">早上</label>
                            </div>
                            <div className="fieldset-item">
                                <input id="afternoon" className="checkbox" name="afternoon" type="checkbox" value="下午" /><label htmlFor="afternoon">下午</label>
                            </div>
                            <div className="fieldset-item">
                                <input id="night" className="checkbox" name="night" type="checkbox" value="晚上" /><label htmlFor="night">晚上</label>
                            </div>
                            
                        </fieldset>
                        <label htmlFor="default-qa">有無券商違約交割紀錄</label>
                        <fieldset id="default-qa">
                            <div className="fieldset-item">
                                <input id="default-true" className="checkbox" name="is-default" type="radio" value="有" checked={defaultSelectedOption === '有'} onChange={handleDefaultOptionChange} /> <label htmlFor="default-true">有</label>
                            </div>
                            <div className="fieldset-item">
                                <input id="default-false" className="checkbox" name="is-default" type="radio" value="無" checked={defaultSelectedOption === '無'} onChange={handleDefaultOptionChange} /> <label htmlFor="default-false">無</label>
                            </div>
                            
                        </fieldset>
                        <label htmlFor="default-dis" style={{ display: defaultSelectedOption === '有' ? 'initial' : 'none' }}>敘述違約時間及現況</label><input id="default-dis" name="default-dis" type="text" placeholder="必填" required={defaultSelectedOption === '其他'} style={{ display: defaultSelectedOption === '有' ? 'initial' : 'none' }} />
                        <hr />
                        <label htmlFor="message">其他訊息</label><textarea id="message" name="message"/>
                        <hr />
                        <fieldset id="rule-read-field">
                            <div className="fieldset-item" onClick={() => setIsDialogOpen(true)} style={{width:"100%"}}>
                                <input ref={ruleReadInput} id="rule-read" className="checkbox" name="rule-read" type="checkbox"  checked={ruleChecked? true : false} readOnly />
                                <label htmlFor="rule-read" style={{width:"100%"}}>
                                    開戶前，我已詳讀<span id="rule-link" >交易規則</span>，並同意遵守相關交易規範。
                                </label>
                                <input ref={ruleReadText} id="rule-read-text" name="rule-read-text" type="text" value={ruleReadMSG} readOnly style={{display: "none"}} />
                            </div>
                            
                        </fieldset>
                        
                        <div
                            className="g-recaptcha"
                            data-sitekey="6LdNh_kpAAAAAIgWM3wk1eZG-xbdLh4H_BUAn-9U"
                            data-theme="dark" data-size={window.innerWidth >= 480? "normal" : "compact"}
                            ref={gRecaptcha}
                            style={{display: "none"}}>
                        </div>
                        <button id="submit" type="submit">提交</button>
                    </form>
                    <Dialog 
                        dialogId="rule-dialog"
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        setRuleChecked={setRuleChecked}
                        elements={
                            <>
                                <img src="/pictures/513139792012378394.jpg" alt="stock-rule" />
                                <img src="/pictures/513139843787391057.jpg" alt="futures-rule" />
                            </>
                        }
                    />
                </section>
            </main>
            <Footer />
        </>
        
    )
    
}

export default ApplyNow;
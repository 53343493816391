import "./FeatCard.css"

const FeatCard = ({title, text, imgSrc}) => {
    return(
        <div className="card feat-card">
            <img src={imgSrc} alt={title} />
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    )
}

export default FeatCard;
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import "./Error404.css";

const Error404 = () => {
    const navigate = useNavigate();

    return(
        <>
            <Header />
            <section className="reading-sec error-sec">
                <h1 id="error-title" className="text-center">不好意思...似乎找不到您尋找的頁面 :(</h1>
                <p id="error-text" className="text-center">頁面可能發生部分錯誤，讓我們引導您回主頁。</p>
                <button id="back-btn" onClick={() => navigate("/")}>返回主頁</button>
            </section>
            <Footer />
        </>
    )
}

export default Error404;
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { createContext, useState} from 'react';
import LocationOBJ from './page-components/components/LocationOBJ';
import Home from "./page-components/Home";
import AboutUs from './page-components/AboutUs';
import Futures from './page-components/Futures';
import Stock from './page-components/Stock';
import ApplyNow from './page-components/ApplyNow';
import Error404 from './page-components/Error404';

export const LocationContext = createContext();

function App() {
  const taiwan = new LocationOBJ("台灣", 318);
  const japan = new LocationOBJ("日本", 290);
  const us = new LocationOBJ("U.S.", 150);
  const allLocations = {
      taiwan: taiwan,
      japan: japan,
      us: us,
  };
  const [currentLocation, setCurrentLocation] = useState(taiwan);

  return (
    <LocationContext.Provider value={{allLocations, currentLocation, setCurrentLocation}}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/futures-teaching' element={<Futures />} />
        <Route path='/stock-teaching' element={<Stock />} />
        <Route path='/apply-now' element={<ApplyNow />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </LocationContext.Provider>
    
    
  );
}

export default App;

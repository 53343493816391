import Header from "./components/Header"
import Footer from "./components/Footer"

const AboutUs = () => {
    return(
        <>
            <Header />
            <h1>About Us Page</h1>
            <Footer />
        </>
    )
}

export default AboutUs;